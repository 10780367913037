<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 mt-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card>
                        <v-toolbar color="red darken-2 white--text" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>
                            Delete profile
                        </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>

                            <p class="text-overline mb-0">Name</p>
                            <p class="mb-0 pb-0">
                                {{ user.pseudonym }}
                            </p>

                            <template v-if="user.account_id">
                            <p class="text-overline mb-0 mt-2">Account</p>
                            <p class="mb-0 pb-0" v-if="true">
                                <!-- TODO: condition should be if the user has an active subscription-->
                                Your subscription will be cancelled.
                            </p>
                            <p class="mb-0 pb-0">
                                All your account data will be deleted.
                            </p>
                            </template>

                            <template v-if="user.xentri_id">
                            <p class="text-overline mb-0 mt-4">Security</p>
                            <p class="mb-0 pb-0">
                                Your Xentri profile will be deleted including any access recovery settings.
                            </p>
                            </template>

                            <p class="text-overline mb-0 mt-4">Confirmation</p>

                            <p>Tap on the button below to delete this profile and associated data.</p>

                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation1" label="I want to delete my profile and all associated data immediately. I understand that none of my personal information will be retained." class="mt-0"></v-checkbox>
                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation2" label="I understand this action is not reversible, and that Cryptium will not be able to provide any customer support related to my deleted profile and my deleted account." class="mt-0"></v-checkbox>
                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation3" label="I hereby disclaim any interest in this profile and account, including any benefits that are or may be associated to this profile or account, including but not limited to any account credits, eligibility for discounts, or participation in any special programs." class="mt-0"></v-checkbox>
                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation4" label="I hereby waive any legal or contractual right to dispute any charge or billing matter related to the deleted account. I agree to indemnify Cryptium from any future charge or claim in any forum regarding the deleted profile or account." class="mt-0"></v-checkbox>

                            <v-alert type="warning" border="left" dense>
                                <template #prepend>
                                    <font-awesome-icon fixed-width :icon="['fas', 'exclamation-triangle']" class="white--text text-body-1"/>
                                </template>
                                <p class="font-weight-bold ml-4 mb-0">This action is immediate and not reversible.</p>
                                <p class="font-weight-light ml-4 mb-0">If you change your mind, you will need to create a new profile.</p>
                            </v-alert>

                            <v-btn color="red" class="white--text" @click="deleteProfile" :disabled="!isDeleteProfileConfirmed">Delete Profile</v-btn>

                        </v-card-text>
                    </v-card>

                    <!-- <v-expansion-panels class="mt-8 mb-6" v-if="isPermitServiceAdmin">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="blue--text"/></span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>Tap on the button below to delete this profile, including all domains, DNS records, and other related data.</p>
                                <p class="font-weight-bold">There will not be any confirmation prompts. This action is not reversible.</p>
                                <p class="mb-0 pb-0">
                                    <v-btn color="red" class="white--text" @click="deleteProfileImmediately">Delete Account</v-btn>
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels> -->
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        accountList: null,
        domainList: null,
        error: null,
        deleteConfirmation1: false,
        deleteConfirmation2: false,
        deleteConfirmation3: false,
        deleteConfirmation4: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            focus: (state) => state.focus,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isDeleteProfileConfirmed() {
            return this.deleteConfirmation1 && this.deleteConfirmation2 && this.deleteConfirmation3 && this.deleteConfirmation4;
        },
    },
    watch: {
        focus() {
            this.loadAccountList();
        },
    },
    methods: {
        async loadAccountList() {
            try {
                this.$store.commit('loading', { loadAccountList: true });
                const response = await this.$client.user(this.session.userId).user.getAccountList();
                if (response?.list) {
                    this.accountList = response.list;
                }
            } catch (err) {
                console.error('loadAccountList failed', err);
            } finally {
                this.$store.commit('loading', { loadAccountList: false });
            }
        },
        onClickAccount(accountId) {
            this.$router.push({ name: 'account-dashboard', params: { accountId } });
        },
        async deleteProfile() {
            try {
                this.$store.commit('loading', { deleteProfile: true });
                const response = await this.$client.user(this.user.id).user.delete();
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Deleted profile' });
                    // NOTE: on successful deletion of user record, server also deletes the session, so we just need to refresh
                    await this.$store.dispatch('refresh');
                    this.$router.push({ name: 'front' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete profile', message: this.$route.params.accountId });
                }
            } catch (err) {
                console.error('failed to delete profile', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete profile', message: this.$route.params.accountId });
            } finally {
                this.$store.commit('loading', { deleteProfile: false });
            }
        },
        /**
         * Service admin can delete the user profile and data immediately (TODO: not implemented yet)
         */
        /*
        async deleteProfileImmediately() {
            try {
                this.$store.commit('loading', { deleteProfileImmediately: true });
                const response = this.$client.main().service.deleteProfile({ accountId: this.$route.params.accountId });
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Deleted account', message: this.$route.params.accountId });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete account', message: this.$route.params.accountId });
                }
            } catch (err) {
                console.error('failed to delete account', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete account', message: this.$route.params.accountId });
            } finally {
                this.$store.commit('loading', { deleteProfileImmediately: false });
            }
        },
        */
    },
    mounted() {
        this.loadAccountList();
        // this.loadDomainList();
    },
};
</script>
